import { useRoutes } from 'react-router-dom';

import { Login } from '@/features/auth/routes/Login';
import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { lazyImport } from '@/utils/lazyImport';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();

  // const commonRoutes = [{ path: '/', element: <Landing /> }];
  const commonRoutes = [{ path: '/', element: <Login /> }];

  if (!auth.user) {
    commonRoutes.push({ path: '*', element: <Login /> });
  }

  if (parseInt(auth.user?.is_mod) || parseInt(auth.user?.is_admin)) {
    console.log('ModToolsRoutes enabled');
    const { ModToolsRoutes } = lazyImport(() => import('@/features/mod_tools'), 'ModToolsRoutes');
    protectedRoutes[0].children.push({
      path: '/mod_tools/*',
      element: <ModToolsRoutes />,
    });
  }

  const routes = auth.user ? protectedRoutes : publicRoutes;

  console.log('auth.user?', auth.user);

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
